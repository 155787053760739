<template>
<offer-vue :title="'Усиление бетонных козырьков'" :imgsrc="'offer_imgs/peak_concrete/peak_concrete.webp'">
    Выполним работы по усилению козырьков - установим надежную металлическую конструкцию, которая не портит внешний вид дома и в тоже время укрепляет козырек, обеспечивая безопасность для жильцов.<br><br>
    <ul><li>Возможны различные варианты решений по укреплению козырьков, как по дизайну, так и по стоимости.</li></ul>

</offer-vue>
<cards-vue 
:type="'products'"
@openModal="(product) => openModal(product)"
:cards="cardsJson"></cards-vue>
<transition name="modal">
    <product-modal
    v-if="isModalVisible"
    :content="modalContent"
    :descriptionCommon="`Вертикальные опоры:<b>
    1200 мм - профильная стальная труба 60х30 мм.
    Укосина:<b>
    1700 мм - профильная стальная труба 60х60 мм 
    Декоративное заполнение:<b>
    профильная стальная труба 15х15 мм. 
    Опора под козырек 3200х1200х3200х1200 – стальной уголок 45х45 мм 
    Окраска эмалью по металлу в цвет по согласованию с заказчиком<b>
    `"
     :pageSection="'Усиление бетонных козырьков'"
     @openForm="openForm()"
     @close="isModalVisible = false">
     </product-modal>
 </transition>
 <transition name="modal">
     <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
 </transition>
</template>

<script>
import cardsJson from './peak-concrete-cards.json'
export default {
name: 'peak-concrete',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style scoped>

</style>