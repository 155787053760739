<template>
<button>
    <slot></slot>
</button>
</template>

<script>
export default {
    name: 'main-button'
}
</script>
<style scoped>
* {
    border: 0px;
}
button {
    cursor: pointer;
    height: 49px;
    width: 187px;
    border-radius: 7px;
    background: #264AC7;
    color: #fff;
    transition: 0.3s ease;
}
:hover {
    background: #0A2278;
    transition: 0.3s ease;
}

slot {
    vertical-align: middle;
}
@media  screen and (max-width: 1366px) and (min-width: 900px) {
    button {
        max-width: 100%;
        font-size: 18px;
    }
}
@media  screen and (max-width: 900px) {
    button {
        max-width: 100%;
        font-size: 14px;
    }
}
</style>