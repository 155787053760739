import { createRouter } from 'vue-router'
import LawnPage from './components/Lawn/LawnPage.vue'
import HomePage from './components/Home/HomePage.vue'
import PeakPage from '@/components/Peak/PeakPage.vue'
import DoorsPage from '@/components/Doors/DoorsPage.vue'
import RailPage from '@/components/Rail/RailPage.vue'
import PeakRepairPage from '@/components/PeakRepair/PeakRepairPage.vue'
import PeakConcretePage from '@/components/PeakConcrete/PeakConcretePage.vue'
import PeakShaftsPage from '@/components/PeakShafts/PeakShaftsPage.vue'
import PeakPitsPage from '@/components/PeakPits/PeakPitsPage.vue'
import GridsFloorPage from '@/components/GridsFloor/GridsFloorPage.vue'
import GridsWindowPage from '@/components/GridsWindow/GridsWindowPage.vue'
import EmptyPage from '@/components/Empty/EmptyPage.vue'
import RampPage from '@/components/Ramp/RampPage.vue'
// import AboutUsPage from '@/components/AboutUs/AboutUsPage.vue'
import PoliticsPage from './components/PoliticsPage.vue'

// const setRedirects = (to, from, next, currentRoute) => {
//     
//     if (from.path === currentRoute) {
//         next({path: '#'})
//     }
//     next()
// }
const routes = [
    { path: '/', component: HomePage },
    { path: '/gazonnye-ograzhdeniya/', component: LawnPage },
    { path: '/kozyrki-nad-vhodami/', component: PeakPage },
    { path: '/dveri/', component: DoorsPage },
    { path: '/poruchni/', component: RailPage },
    { path: '/remont-kozyrkov/', component: PeakRepairPage },
    { path: '/usilenie-betonnyh-kozyrkov/', component: PeakConcretePage },
    { path: '/navesy-nad-ventshahtami/', component: PeakShaftsPage },
    { path: '/ograzhdeniya-priyamkov/', component: PeakPitsPage },
    { path: '/reshetki-na-tekh-ehtazhah/', component: GridsFloorPage },
    { path: '/reshetki-na-podvalnye-okna/', component: GridsWindowPage },
    { path: '/pandusy/', component: RampPage },
    { path: '/o-nas/', component: EmptyPage },
    { path: '/conf', component: PoliticsPage},
    { path: '/empty', component: EmptyPage},
]
export default function (history) {
    // routes.push({ path: '/:\\*', redirect: '#'});

    return createRouter({
        history,
        routes,
        scrollBehavior() {
            return { top: 0 }
        }
    })
}