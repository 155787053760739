<template>
<div class="row-title">
    <slot></slot>
</div>
</template>

<script>
export default {
    name: 'row-title'
}
</script>

<style scoped>
.row-title {
    background: rgba(38, 74, 199, 0.1);
    z-index: -100;
    font-size: 44px;
    text-align: center;
    color: #373737;
    opacity: 90%;
    padding: 25px 0;
}
</style>