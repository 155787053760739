import { createApp } from 'vue';
import createRouter from './router'
import { createWebHistory } from 'vue-router'
import App from './App.vue'
import { createStore } from 'vuex'
import './assets/main.css'

import Header from '@/components/Home/Header.vue'
import Topbar from '@/components/Home/Topbar.vue'
import Cards from '@/components/Home/Cards.vue'
import Footer from '@/components/Home/Footer.vue'
import Form from '@/components/UI/Form.vue'
import MainModal from '@/components/UI/MainModal.vue'
import ServiceCard from '@/components/UI/ServiceCard.vue'
import MainButton from '@/components/UI/MainButton.vue'
import HomeModal from '@/components/Home/HomeModal.vue'
import ProductCard from '@/components/UI/ProductCard.vue'
import CloseButton from '@/components/UI/CloseButton.vue'
import Offer from '@/components/UI/Offer.vue'
import RowTitle from '@/components/UI/RowTitle.vue'
import MainRadio from '@/components/UI/MainRadio.vue'
import ProductModal from '@/components/UI/ProductModal.vue'
import HomeButton from '@/components/UI/HomeButton.vue'
import MainInput from '@/components/UI/MainInput.vue'
import MainCalc from '@/components/UI/MainCalc.vue'
import SideBarButton from '@/components/UI/SideBarButton.vue'
import Paragraph from '@/components/UI/Paragraph.vue'
import CardButton from '@/components/UI/CardButton.vue'
import LineInvalid from '@/components/UI/LineInvalid.vue'
import PoliticsPage from '@/components/PoliticsPage.vue'


const store = createStore({
    state: {
        modal: {
            items: new Array,
            source: new String,
            isModalVisible: false,
        },
        postBody: {
            product: new Object,
            user: new Object
        },
        img: {
            setAssetsPath(imgsrc) {
                return imgsrc?.slice(0, 4) === 'data' ? imgsrc : process.env.VUE_APP_ASSETS_PATH + imgsrc;
            },
            imgPath(imgsrc) {
                return require(`@/assets/${imgsrc}`)
            },
            adaptiveImgPath(windowWidth, imgsrc) {
                // let assetsPath = imgsrc.slice(0, 4) === 'data' ? process.env.VUE_APP_ASSETS_PATH : '';
                let fullPath = (path) => {return require(`@/assets/${path}`)}
                let setAdaptiveImg = (p) => imgsrc.split('.').join(`${p}.`)

                let width = windowWidth;
                let adaptiveImg;
                if (width > 2560) {
                    adaptiveImg = setAdaptiveImg('-4k');
                } else if (width > 1920) {
                    adaptiveImg = setAdaptiveImg('-2k');
                } else if (width > 1280) {
                    adaptiveImg = setAdaptiveImg('-fhd');
                } else if (width > 820) {
                    adaptiveImg = setAdaptiveImg('-hd');
                } else if (width > 430) {
                    adaptiveImg = setAdaptiveImg('-820');
                } else if (width <= 430) {
                    adaptiveImg = setAdaptiveImg('-430');
                }
                try {
                    return fullPath(adaptiveImg)
                } catch {
                    return fullPath(imgsrc)
                }
            }
        }    
    },
    mutations: {
        CHANGE_MODAL_ITEMS(state, payload) {
            state.modal.items = payload;
        },
        CHANGE_MODAL_SOURCE(state, payload) {
            state.modal.source = payload;
        },
        CHANGE_POSTBODY_PRODUCT(state, payload) {
            state.postBody.product = payload;
        },
        CHANGE_POSTBODY_USER(state, payload) {
            state.postBody.user = payload;
        }
    },
    getters: {
        modalItemsCount(state) {
            return state.modal.items.length;
        }
    }
})

const components = [
    Header,
    Topbar,
    Cards,
    ServiceCard,
    Footer,
    Form,
    MainModal,
    MainButton,
    HomeModal,
    ProductCard,
    CloseButton,
    Offer,
    RowTitle,
    MainRadio,
    ProductModal,
    HomeButton,
    MainInput,
    MainCalc,
    SideBarButton,
    Paragraph,
    CardButton,
    LineInvalid,
    PoliticsPage
]
const app = createApp(App)
components.map(component => app.component(component.name, component));

app.use(store);

const router = createRouter(createWebHistory());
app.use(router);
if (router.isReady()) {
    app.mount('#app');
}
// export {store, components}
