<template>
<offer-vue :title="'Двери'" :imgsrc="'offer_imgs/doors/doors.webp'">
    Изготавливаем на собственном производстве и устанавливаем металлические двери под любые размеры и формы проемов.<br><br>
    <ul><li>В стоимость входит изготовление, окраска доставка и монтаж</li>
    <li>Производим врезку световых окон в существующие входные  двери</li></ul>
</offer-vue>
<cards-vue
@openModal="(product) => openModal(product)"
:type="'products'"
:isNativeImgsOnCards="true"
:renderBorderOnCards="true"
:cards="cardsJson"></cards-vue>
<transition name="modal">
    <product-modal
    v-if="isModalVisible"
    :content="modalContent"
    :pageSection="'Двери'"
    @openForm="openForm()"
    @close="isModalVisible = false">
    </product-modal>
</transition>
<transition name="modal">
    <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
</transition>
</template>

<script>
import cardsJson from './doors-cards.json'
export default {
name: 'doors-vue',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style>

</style>