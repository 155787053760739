<template>
    <offer-vue :title="'ПАНДУСЫ'" :imgsrc="'offer_imgs/ramp/ramp.webp'">
        Установим пандусы в подъездах, на крыльцах Вашего дома <br><br>
        Для обеспечения доступа маломобильным группам населения предлагаем пандусы любой конфигурации с учетом требований, предъявляемых к пандусам, а также разной ширины лестниц.<br><br>
        <ul><li>В стоимость входит изготовление, краска, доставка и монтаж</li></ul>
    </offer-vue>
    <cards-vue
    :type="'products'"
    :renderBorderOnCards="false"
    @openModal="(product) => openModal(product)"
    :cards="cardsJson"
    ></cards-vue>
    <transition name="modal">
        <product-modal
        v-if="isModalVisible"
        :content="modalContent"
        :pageSection="'Пандусы'"
        @openForm="openForm()"
        @close="isModalVisible = false">
        </product-modal>
    </transition>
    <transition name="modal">
        <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
    </transition>
    </template>
    
    <script>
    import cardsJson from './ramp-cards.json'
    export default {
    name: 'ramp-page',
    data() {
        return {
           isModalVisible: false,
           modalContent: new Object,
           isFormVisible: false,
           cardsJson: cardsJson
        }
    },
    methods: {
        openModal(product) {
            this.isModalVisible = !this.isModalVisible;
            this.modalContent = product;
        },
        openForm() {
            this.isFormVisible = true;
            this.isModalVisible = false;
        }
    }
    }
    </script>
    
    <style>
    
    </style>