<template>
<offer-vue :title="'Решетки на подвальные окна'" :imgsrc="'offer_imgs/grids_window/grids_window.webp'">
    Изготавливаем и устанавливаем решетки на подвальные окна<br><br>
    Решетки устанавливаются в оконном проеме цоколя здания. Крепление – анкерное или на сварке. Возможны варианты, как глухих, так и открывающихся на петлях, а также съёмных решеток.<br>
    Индивидуальное исполнение, любые формы и размеры, любой дизайн заполнение решеток.<br><br>
    <ul><li>В стоимость входит изготовление, окраска доставка и монтаж</li></ul>
    
</offer-vue>
<cards-vue 
@openModal="(product) => openModal(product)"
:type="'products'"
:cards="cardsJson"></cards-vue>
<transition name="modal">
    <product-modal
    v-if="isModalVisible"
    :content="modalContent"
    :pageSection="'Решетки на подвальные окна'"
    @openForm="openForm()"
    @close="isModalVisible = false">
    </product-modal>
</transition>
<transition name="modal">
    <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
</transition>
</template>

<script>
import cardsJson from './grids-cards.json'
export default {
name: 'grids-window-page',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style>

</style>