<template>
    <div class="offer">
        <div class="background">
            <div class="bg-img-wrapper">
                <img class="bg-img" 
                v-if="imgsrc !== undefined"
                :alt="isHomeOffer ? title: 'Астрей. Металлоконструкции'"
                :src="isHomeOffer ? $store.state.img.setAssetsPath(imgPath) : $store.state.img.setAssetsPath(adaptiveImgPath)">
                <!-- Исправить путь к картинке!!! -->
            </div>
        </div>
        <div class="text-place-container" :class="{'title-center': isMobile}">
            <div class="text-place">
                <div class="text-wrapper">
                    <h1 class="title" :class="{'title-home': isHomeOffer}">{{title}}</h1>
                    <div class="content" v-if="!isMobile"><slot></slot></div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-container">
        <paragraph-vue v-if="isMobile ">
            <slot></slot>
        </paragraph-vue>
    </div>
</template>

<script>
export default {
name: 'offer-vue',
data() {
    return {
        windowWidth: 0,
        assetsPath: process.env.VUE_APP_ASSETS_PATH,
        onResize: () => {
            this.windowWidth = window.innerWidth;
            this.$emit('windowWidth', this.windowWidth)
        }
    }
},
props: {
    imgsrc: {
        type: String
    },
    title: {
        type: String
    },
    isHomeOffer: {
        type: Boolean,
        default: false
    }
},
computed: { 
    isMobile() {
        return this.windowWidth < 1200 ? true : false
    },
    imgPath() {
        return this.$store.state.img.imgPath(this.imgsrc)
    },
    adaptiveImgPath() {
        return this.$store.state.img.adaptiveImgPath(this.windowWidth, this.imgsrc)
    }
},
mounted() {
    this.windowWidth = window.innerWidth;
    this.onResize();
    window.addEventListener('resize', this.onResize);
},
unmounted() {
    window.removeEventListener('resize', this.onResize);
}
}
</script>

<style scoped>

@media screen and (max-width: 600px) {
    .title {
        font-size: 16px;
        line-height: 24px;
    }
}
@media screen and (min-width: 900px) {
    .title-home {
        font-size: 5vw;
        line-height: 6vw;
    }
}
@media screen and (max-width: 900px) {
    h1 {
        font-size: 24px;
        line-height: 32px;
    }
}
.p-container {
    padding: 0 20px;
}
.offer {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 40.1vw;
}
.background {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: inherit;
}
.text-place-container {
    width: 54vw;
    background: linear-gradient(304.56deg, #FFFFFC 9.5%, #DADADA 90.25%);
    clip-path: circle(35.3vw at 18.6vw 11.8vw);
}
.title {
    display: block;
    max-width: 45vw;
    margin-bottom: 2.6vw;
    color: #264AC7;
}
.title-center {
    display: grid;
    place-items: center;
}
.content {
    display: block;
    max-width: 40vw;
}
.box {
    width: 50%;
}
.bg-img-wrapper {
    position: relative;
    height: inherit;
    width: inherit;
}
.bg-img-wrapper:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(99.14deg, #000000 19.21%, rgba(0, 0, 0, 0) 100%);
}
.bg-img {
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
    object-fit: cover;
}
.text-wrapper {
    margin: 5.5vw 5.5vw 0px 8.3vw;
    width: fit-content;
}
</style>