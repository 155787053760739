<template>
<offer-vue :title="'ПОРУЧНИ'" :imgsrc="'offer_imgs/rail/rail.webp'">
    Предлагаем установку поручней, ограждений из нержавеющей стали, металлических окрашенных или ПВХ поручней в подъезде или на крыльце Вашего дома  
</offer-vue>
<cards-vue
:type="'products'"
:renderBorderOnCards="false"
@openModal="(product) => openModal(product)"
:cards="cardsJson"
></cards-vue>
<transition name="modal">
    <product-modal
    v-if="isModalVisible"
    :content="modalContent"
    :pageSection="'Поручни'"
    @openForm="openForm()"
    @close="isModalVisible = false">
    </product-modal>
</transition>
<transition name="modal">
    <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
</transition>
</template>

<script>
import cardsJson from './rail-cards.json'
export default {
name: 'rail-page',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style>

</style>