<template>
<div class="app">
    <div class="sticky">
        <header-vue @openForm="isFormVisible = true" 
        ></header-vue>
        <!-- <side-bar-button v-if="sideBarBtnIsVisible" @click="sideBarIsVisible = !sideBarIsVisible"></side-bar-button> -->
        <transition name="sidebar">
            <!-- v-if="sideBarIsVisible"
            @closeSideBar="sideBarIsVisible = false" -->
            <topbar-vue
            @click.capture="isFormVisible = false"
            :pages="[
                {title: 'Главная', link: '/'},
                {title: 'Услуги', dropbox: [
                    {title: 'Ограждения', sections: [
                        {title: 'Газонные ограждения', imgsrc: require('@/assets/home_cards_imgs/lawn-455.webp'), link: '/gazonnye-ograzhdeniya/'},
                        {title: 'Заборы секционные', link: '/empty'},
                    ]},
                    {title: 'Козырьки', sections: [
                        {title: 'Козырьки над входами', imgsrc: require('@/assets/home_cards_imgs/peak-455.webp'), link: '/kozyrki-nad-vhodami/'},
                        {title: 'Ремонт козырьков', imgsrc: require('@/assets/home_cards_imgs/peak_repair.webp'), link: '/remont-kozyrkov/'},
                        {title: 'Усиление бетонных козырьков', imgsrc: require('@/assets/home_cards_imgs/peak_concrete-455.webp'), link: '/usilenie-betonnyh-kozyrkov/'},
                        {title: 'Навесы над вентшахтами', imgsrc: require('@/assets/home_cards_imgs/peak_shafts-455.webp'), link: '/navesy-nad-ventshahtami/'},
                        {title: 'Ограждения спусков в подвал', imgsrc: require('@/assets/home_cards_imgs/peak_pits-455.webp'), link: '/ograzhdeniya-priyamkov/'}
                    ]},
                    {title: 'Двери', link: '/dveri/'},
                    {title: 'Пандусы', link: '/pandusy/'},
                    {title: 'Поручни', link: '/poruchni/'},
                    {title: 'Решетки', sections: [
                        {title: 'Решетки на тех. этажах', imgsrc: require('@/assets/home_cards_imgs/grids_floor-455.webp'), link: '/reshetki-na-tekh-ehtazhah/'},
                        {title: 'Решетки на подвальные окна', imgsrc: require('@/assets/home_cards_imgs/grids_window-455.webp'), link: '/reshetki-na-podvalnye-okna/'}
                    ]},
                    {title: 'Велопарковки'},
                    {title: 'Навесы'},
                    {title: 'Другое'}
                ]},
                {title: 'Наши работы'},
                {title: 'Акции', isRed: true},
                {title: 'О нас', link: '/o-nas/'},
            ]"></topbar-vue>
        </transition>
    </div>
    <router-view/>
    <footer-vue @openForm="isFormVisible = true"></footer-vue>
</div>
<transition name="modal">
    <main-modal 
    v-if="isFormVisible" 
    @close="isFormVisible = false">
        <form-vue @closeModal="isFormVisible = false"></form-vue>
    </main-modal>
</transition>
</template>

<script>
export default {
data() {
    return {
        isFormVisible: false
    }
},
}
</script>

<style>
.modal-enter-from, .modal-leave-to {
    opacity: 0;
}

@media screen and (max-width: 900px) {
    * {
        font-size: 16px;
        line-height: 24px;
    }
}
@media screen and (min-width: 900px) {
    * {    
        font-size: 18px;
        line-height: 28px;
    }
    /* h5 {
        font-size: 1.7vw;
        line-height: 2vw;
    } */
    /* span {
        font-weight: 500;
    } */
    h1, h2 {
        font-size: 3.3vw;
        line-height: 4vw;
    }
    h3 {
        font-size: 2.1vw;
        line-height: 3vw;
    }
}
* {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #373737;
    border: 0px;
}
h1, h2 {
    font-weight: 700;
}
h3 {
    font-weight: 500;
}
b {
    font-weight: 600;
}
a {
    text-decoration: none;
}
.header {
    display: block;
}
.sticky {
    position: sticky;
    top: 0px;
    z-index: 2;
}
.sidebar-enter-active, .sidebar-leave-active {
    transition: transform 0.4s ease;
} 
.sidebar-enter-from,
.sidebar-leave-to {
    transform: translateX(100%);

}
@media  screen and (max-width: 900px) {
    .sticky {
        overflow-x: clip;
    }
}
</style>