<template>
    <main-modal @close="$emit('close')">
        <cards-vue
        :type="'services'"
        :cards="this.$store.state.modal.items">
        </cards-vue>
    </main-modal>
</template>

<script>
export default {
    name: 'home-modal',
    props: {
        cards: {
            type: Array
        }
    }
}
</script>

<style scoped>

</style>