<template>
    <p class="valid-control">
        <slot></slot>
    </p>
</template>

<script>
export default {
name: 'line-invalid'
}
</script>

<style scoped>
.valid-control {
    font-size: 14px;
    color: red;
    padding-left: 20px;
}
</style>