<template>
<div class="lawn-container">
    <offer-vue class="offer" :title="'Газонные ограждения'" :imgsrc="'offer_imgs/lawn/lawn.webp'">
        Изготавливаем и устанаваливаем газонные ограждения
        <ul>
            <li>Гарантия 2 года</li> 
            <li>Все цвета</li>
            <li>Любые размеры</li>
            <li>Большой выбор моделей</li>
            <li>В стоимость входит изготовление, краска, доставка и монтаж</li>
        </ul>
    </offer-vue>
    <cards-vue
    @openModal="(product) => openModal(product)"
    :type="'products'"
    :cards="cardsJson"
    ></cards-vue>
    <transition name="modal">
        <product-modal
        v-if="isModalVisible"
        :unitPrice="'руб./метр'"
        :descriptionCommon="
`Размеры:<b>
Длина секции 2000 мм
Высота секции 400 мм  
Длина столбов 1200 мм обеспечивает прочность и устойчивость конструкции!

Материалы:<b>
Вертикальные столбы: профильная труба 25х25 мм (стандарт)/
40х40 мм (усиленное)
Горизонтальные элементы: профильная труба 20х20 мм 
Вертикальные элементы (заполнение): профильная труба 15х15 мм`"
        :content="modalContent"
        :pageSection="'Газонные ограждения'"
        @openForm="openForm()"
        @close="isModalVisible = false">
        </product-modal>
    </transition>
    <transition name="modal">
        <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
    </transition>
</div>
</template>

<script>
import cardsJson from './lawn-cards.json'
export default {
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
},
mounted() {
    document.dispatchEvent(new Event('lawn-ready'))
}
}
</script>

<style scoped>
ul {
    padding-left: 0.8vw;
}
li {
    margin-top: 1.3vw;
}
</style>