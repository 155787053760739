<template>
<offer-vue :title="'Ограждения спусков в подвал'" :imgsrc="'offer_imgs/peak_pits/peak_pits.webp'">
    Предлагаем изготовление и установку ограждений приямков для защиты ступеней в подвал от осадков, для предотвращения попадания посторонних лиц в подвал (приямок).<br><br>
    <ul><li>В стоимость входит изготовление, окраска доставка и монтаж! </li></ul>
</offer-vue>
<cards-vue 
:type="'products'"
:renderBorderOnCards="false"
@openModal="(product) => openModal(product)"
:cards="cardsJson"></cards-vue>
<paragraph-vue>
    Конструкция состоит из металлокаркаса с крышей, покрытой различными кровельными материалами по желанию заказчика:
    <ul><li>стальным листом толщиной от 2 мм (рекомендуется в местах с большим риском падения сосулек),</li>
    <li>металлочерепицей,</li>
    <li>ондулином (сочетает в себе все лучшие качества профлиста и при этом создает минимальный шум при попадании капель дождя),</li>
    <li>профлистом (экономичный вариант покрытия!),</li>
    <li>сотовым или монолитным поликарбонатом. Поликарбонатные листы обладают высокой прочностью – в 200 раз более устойчивые к повреждениям, чем стекло. Гибкость этого материала, позволяет создавать козырьки для приямков различной формы, а широкая цветовая гамма – подобрать подходящий к фасаду вариант.</li><br>
        </ul>
    Каркас крыши – стальная профильная труба 40х40 мм, 40х20 мм. 
    <ul><li>cтенки – каркас со стойками из стальной профильной трубы 40х40 мм;</li>
    <li>вертикальное заполнение – стальная профильная труба 15х15 мм.</li>
    <li>Дверь-решетка: стальная профильная труба 40х20 мм, 15х15 мм.</li>
    <li>Комплектация двери: петли, проушины под навесной замок.</li>
    <li>Окраска всей конструкции эмалью по металлу в цвет по согласованию с заказчиком</li></ul>
</paragraph-vue>
<transition name="modal">
    <product-modal
    v-if="isModalVisible"
    :content="modalContent"
    :pageSection="'Ограждения приямков'"
    @openForm="openForm()"
    @close="isModalVisible = false">
    </product-modal>
</transition>
<transition name="modal">
    <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
</transition>
</template>

<script>
import cardsJson from './peak-pits-cards.json'
export default {
name: 'peak-pits',
data() {
    return {
       isModalVisible: false,
       modalContent: new Object,
       isFormVisible: false,
       cardsJson: cardsJson
    }
},
methods: {
    openModal(product) {
        this.isModalVisible = !this.isModalVisible;
        this.modalContent = product;
    },
    openForm() {
        this.isFormVisible = true;
        this.isModalVisible = false;
    }
}
}
</script>

<style scoped>

</style>