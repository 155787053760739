<template>
    <div class="app-container">
        <offer-vue class="offer" 
        :title="'АСТРЕЙ'" 
        :isHomeOffer="true"
        :imgsrc="'offer_imgs/main/main.webp'">
        <div class="content">
            <p class="main-description">Изготавливаем и устанавливаем металлоконструкции для многоквартирных домов.</p><br>
            <p class="main-description">Работаем с 2009 года!</p>
            <div class="bottom-content">
                <span>Бесплатный выезд на замеры!</span><br>
                <main-button class="btn" @click="isFormVisible = true" @close="isFormVisible = false">Оформить заявку</main-button>
            </div>
        </div>
        </offer-vue>
        <row-title><h2>НАШИ УСЛУГИ</h2></row-title>
        <cards-vue class="cards"
        :type="'services'"
        :cards="
            [
                {title: 'ОГРАЖДЕНИЯ', imgsrc: require('@/assets/home_cards_imgs/lawn-455.webp'), sections: [
                    {title: 'Газонные ограждения', imgsrc: require('@/assets/home_cards_imgs/lawn-455.webp'), link: '/gazonnye-ograzhdeniya/'},
                    {title: 'Заборы секционные', link: '/empty'},
                ]},
                {title: 'КОЗЫРЬКИ', imgsrc: require('@/assets/home_cards_imgs/peak-455.webp'), sections: [
                    {title: 'Козырьки над входами', imgsrc: require('@/assets/home_cards_imgs/peak-455.webp'), link: '/kozyrki-nad-vhodami/'},
                    {title: 'Ремонт козырьков', imgsrc: require('@/assets/home_cards_imgs/peak_repair.webp'), link: '/remont-kozyrkov/'},
                    {title: 'Усиление бетонных козырьков', imgsrc: require('@/assets/home_cards_imgs/peak_concrete-455.webp'), link: '/usilenie-betonnyh-kozyrkov/'},
                    {title: 'Навесы над вентшахтами', imgsrc: require('@/assets/home_cards_imgs/peak_shafts-455.webp'), link: '/navesy-nad-ventshahtami/'},
                    {title: 'Ограждения спусков в подвал', imgsrc: require('@/assets/home_cards_imgs/peak_pits-455.webp'), link: '/ograzhdeniya-priyamkov/'}
                ]},
                {title: 'ДВЕРИ', imgsrc: require('@/assets/home_cards_imgs/doors-455.webp'), link: '/dveri/'},
                {title: 'ПАНДУСЫ', imgsrc: require('@/assets/home_cards_imgs/ramp-455.webp'), link: '/pandusy/'},
                {title: 'ПОРУЧНИ', imgsrc: require('@/assets/home_cards_imgs/rail-455.webp'), link: '/poruchni/'},
                {title: 'РЕШЕТКИ', imgsrc: require('@/assets/home_cards_imgs/grids_floor-455.webp'), sections: [
                    {title: 'Решетки на тех. этажах', imgsrc: require('@/assets/home_cards_imgs/grids_floor-455.webp'), link: '/reshetki-na-tekh-ehtazhah/'},
                    {title: 'Решетки на подвальные окна', imgsrc: require('@/assets/home_cards_imgs/grids_window-455.webp'), link: '/reshetki-na-podvalnye-okna/'}
                ]},
                {title: 'ВЕЛОПАРКОВКИ', imgsrc: require('@/assets/home_cards_imgs/park-455.webp'), link: '/empty'},
                {title: 'НАВЕСЫ', imgsrc: require('@/assets/home_cards_imgs/tent-455.webp'), link: '/empty'},
                {title: 'ДРУГОЕ', imgsrc: require('@/assets/home_cards_imgs/other-455.webp'), link: '/empty'}
            ]
        ">
        </cards-vue>
        <div class="row-title-bottom">
            <h3 class="text">
                СВЯЖИТЕСЬ С НАМИ, ЧТОБЫ РАССЧИТАТЬ СТОИМОСТЬ
            </h3>
            <h3 class="phone">
                (812) 982 32 12
            </h3>
        </div>
        <transition name="modal">
            <main-modal v-if="isFormVisible" @close="isFormVisible = false"><form-vue></form-vue></main-modal>
        </transition>
    </div>
</template>

<script>
export default 
{
name: "home-vue",
data() {
    return {
        isFormVisible: false
    };
}
}
</script>

<style scoped>
@media screen and (min-width: 900px) {
    span {
        font-size: 1.9vw;
        line-height: 2.3vw;
    }   
}
span {
    font-weight: 500;
}
.row-title-bottom {
    margin: 30px 9vw;
    text-align: center;
    color: #373737;
    opacity: 90%;
}
.offer {
    position: relative;
}
.btn {
    margin-top: 1.8vw;
    box-shadow: 0px 4px 4px 0px #00000040;
}
span {
    color: #264AC7;
}
@media screen and (max-width: 1199px) {
    .bottom-content {
        padding-top: 20px;
    }
    .content {
        text-align: center;
    }
}
@media screen and (min-width: 1200px) {
    .bottom-content {
        position: absolute;
        bottom: 3.4vw;
    }
}
@media screen and (min-width: 900px) {
    .main-description {
        font-size: 1.7vw;
        line-height: 2vw;
    }
}
</style>