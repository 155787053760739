<template>
<button>
    <slot></slot>
</button>
</template>

<script>
export default {
name: 'card-button'
}
</script>

<style scoped>
* {
border: 0px;
}
button {
    cursor: pointer;
    height: 49px;
    width: 187px;
    border-radius: 7px;
    border: 1px solid #264AC7;
    color: #264AC7;
    background: white;
    transition: 0.3s ease;
}
:hover {
    background: #264AC7;
    color: white;
    transition: 0.3s ease;
}

slot {
vertical-align: middle;
}
@media  screen and (max-width: 1366px) and (min-width: 900px) {
button {
max-width: 100%;
font-size: 18px;
}
}
@media  screen and (max-width: 900px) {
button {
max-width: 100%;
font-size: 14px;
}
}
</style>