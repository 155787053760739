<template>
  <footer class="footer-container">
    <div class="co-info">
      © АСТРЕЙ, 2024
      <div class="link">
        <router-link :to="'/conf'">Политика конфиденциальности</router-link> 
      </div>
    </div>
    <div class="contacts">
      <div class="vk">
        <a href="https://vk.com/club225760557"><img alt="vk" :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/vk_blue.svg'))" width="22" height="22"></a>
      </div>
      <div class="header-mail">
        <img alt="e-mail" :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/mail_blue.svg'))" width="22" height="22">
        <p>spb.astrei@yandex.ru</p>
      </div>
      <div class="header-phone">
        <img alt="Телефон" :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/phone_blue.svg'))" width="22" height="22">
        <p>(812) 982 32 12</p>
      </div>
      <div class="wa-phone">
        <img alt="whatsapp" :src="$store.state.img.setAssetsPath($store.state.img.imgPath('icons/whatsapp.svg'))" width="22" height="22">
        <p>+7 905 222-32-12</p>
      </div>
      <div class="action-item call-title" @click="$emit('openForm')">
        <p>ЗАКАЗАТЬ ЗВОНОК</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
name: 'footer-vue'
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .footer-container {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 20px;
  }
}
.link {
  text-align: center;
  text-decoration: underline;
  margin-top: 10px;
}
.action-item {
  cursor: pointer;
}
.action-item:hover {
  text-decoration: underline;
}
.contacts > div {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
a {
  display: contents;
}
.footer-container {
  background: #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 5vw;
}
</style>