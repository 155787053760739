<template>
<button>
    <slot></slot>
</button>
</template>

<script>
export default {
    name: 'home-button'
}
</script>

<style scoped>
* {
    border: 0px;
}
button {
    cursor: pointer;
    height: 104px;
    width: 512px;
    border-radius: 49px;
    background: #264AC7;
    color: #fff;
    transition: 0.3s ease;
    font-size: 30px;
}
:hover {
    background: #0A2278;
    transition: 0.3s ease;
}

slot {
    vertical-align: middle;
}
@media screen and (max-width: 600px) {
    button {
        width: 250px;
        font-size: 20px;
    }
}
</style>