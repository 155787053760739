<template>
<div class="cards-container">
        <div class="wrapper" v-if="type === 'services'">
            <service-card class="card"
            v-for="(card, index) in cards" :key="index"
            @openModal="$emit('openModal')"
            :card="card"
            :loading="index === 0 ? 'eager' : 'lazy'">
            </service-card>
        </div>
        <div class="wrapper" v-if="type === 'products'">
            <product-card class="card"
            v-for="(card, index) in cards" :key="index"
            @openModal="$emit('openModal', card)"
            :loading="index === 0 ? 'eager' : 'lazy'"
            :isNativeImg="isNativeImgsOnCards"
            :content="card"
            :isBorder="renderBorderOnCards">
            </product-card>
        </div>
</div>
</template>

<script>
export default {
name: 'cards-vue',
props: {
    cards: {
        type: Array
    },
    type: {
        type: String
    },
    isNativeImgsOnCards: {
        type: Boolean,
        default: false
    },
    renderBorderOnCards: {
        type: Boolean,
        default: true
    }
}
}
</script>

<style scoped>
@media screen and (min-width: 601px) {
    .card {
        margin: 20px;
    }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
    .wrapper {
        grid-template-columns: auto auto;
    }
}
@media screen and (min-width: 901px) {
    .wrapper {
       grid-template-columns: auto auto auto;
    }
}
@media screen and (max-width: 600px) {
    .card {
        margin: 3vw;
    }
    .wrapper {
        grid-template-columns: auto;
    }
}
.wrapper {
    display: grid;
}
button {
    border: 0px;
    background: #fff;
}
.cards-container {
    display: flex;
    justify-content: center;
}
.card {
    display: block;
}
</style>