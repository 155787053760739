<template>
    <router-link v-if="card.link !== undefined" :to="getLink()">
        <product-card 
        :content="card"
        :loading="loading"
        :isBorder="false"
        @openModal="openModal(), closeModal()"></product-card>
    </router-link>
    <product-card
    v-else
    :loading="loading"
    :content="card"
    :isBorder="false"
    @openModal="openModal(), closeModal()">
    </product-card>
</template>

<script>
export default {
name: 'service-card',
props: {
    card: {
        type: Object
    },
    loading: {
        type: String,
        default: 'lazy'
    }
},
methods: {
    openModal() {
        if (this.card.sections !== undefined) {
            this.$store.commit('CHANGE_MODAL_ITEMS', this.card.sections);    
            this.$emit('openModal');
        }
    },
    closeModal() {
        if (this.card.link !== undefined) {
            this.$store.commit('CHANGE_MODAL_ITEMS', []);
        }
    },
    getLink() {
        if (this.card.sections === undefined) {
            return this.card.link !== undefined ? this.card.link : '/empty';
        } else {
            return ''
        }
    },
}
}
</script>

<style scoped>
/*.card {
    display: block;
    background: #f5f5f5;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
}
.card-content {
    width: 300px;
    height: 269px;
    display: block;
    border-radius: 7px;
}
.card-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3%;
}
.card-title {
    font-size: 24px;
    padding: 20px 0px;
    text-align: center;
    color: #373737;
}
.card-btn {
    display: inline-block;
    margin-bottom: 20px;
}
@media  screen and (max-width: 1366px) and (min-width: 900px) {
    .card-btn {
        margin-bottom: 1.1vw;
    }
    .card-title {
        padding: 1.1vw;
    }
    .card-content {
        width: 23vw;
        height: 21vw;
    }
}
@media  screen and (max-width: 900px) {
    .card-btn {
        margin-bottom: 1.6vw;
        width: 30vw;
    }
    .card-title {
        padding: 1.6vw;
        font-size: 16px;
    }
    .card-content {
        width: 35vw;
        height: 32vw;
    }
}
@media screen and (max-width: 600px) {
    .card-title {
        font-size: 12px;
    }
}*/
</style>